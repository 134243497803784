<template>
  <div id="Ovrigt">
    <v-container>
      <v-card>
        <v-card-title>Kontakta Styrelsen</v-card-title>
        <v-card-text>
          <p>styrelsen (a) nm56.se</p>
        </v-card-text>
      </v-card>

      <v-card>
        <v-card-title>Bra att ha nummer</v-card-title>
        <v-card-text>
          <p>Underhåll av fastigheten: </p>

          <p>
          Vatten och avfall:
          </p>
          <p>
          Rörmockare:
          </p>
          <p>
            Elektriker:
          </p>
          <p>
            Ekonomi (SBC):
          </p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'Ovrigt',
  }
</script>

<style scoped>

</style>
